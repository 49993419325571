import React from 'react'
import ios from '../assets/images/app-store.svg'
import google from '../assets/images/google-play.svg'

const Footer = props => (
  <footer id="footer">
    <div className="inner">
      <ul className="icons">
        <li>
          <a
            href="https://www.facebook.com/3muscler/?ref=bookmarks"
            className="icon alt fa-facebook"
          >
            <span className="label">Facebook</span>
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/3musclers/?hl=zh-tw"
            className="icon alt fa-instagram" 
          >
            <span className="label">Instagram</span>
          </a>
        </li>
        <li>
          <a
            href="https://apps.apple.com/tw/app/3musclers-%E4%B8%89%E5%81%A5%E5%AE%A2%E5%81%A5%E8%BA%AB%E4%BF%B1%E6%A8%82%E9%83%A8/id1486517436"
            style={{top:'10px'}}
				className='icon svg'
          >
            <img src={ios} alt='ios'/>
          </a>
        </li>
        <li>
          <a
            href="https://play.google.com/store/apps/details?id=com.appworkout.fitbutler.threemusclers&hl=zh_TW"
            style={{top:'10px'}}
				className='icon svg'
          >
            <img src={google} alt='google'/>
          </a>
        </li>
      </ul>
      <ul className="copyright">
        <li>&copy; 2021 3musclers </li>
      </ul>
    </div>
  </footer>
)

export default Footer
