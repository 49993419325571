import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import pic1 from '../assets/images/logo.svg'

const Header = (props) => (
    <header id="header" className="alt">
        <Link to="/" className="logo">
		  <img src={pic1} className='logo' style={{marginTop:'1em',position:'absolute',left:'-15px'}} alt='3musclers logo'/>
		  </Link>
        <nav>
            <a className="menu-link" onClick={props.onToggleMenu} href="javascript:;">Menu</a>
        </nav>
    </header>
)

Header.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Header
