import React from 'react'
import PropTypes from 'prop-types'

import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import '../assets/scss/main.scss'
import Header from './Header'
import Menu from './Menu'
import Contact from './Contact'
import Footer from './Footer'

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMenuVisible: false,
      loading: 'is-loading',
    }
    this.handleToggleMenu = this.handleToggleMenu.bind(this)
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ loading: '' })
	 }, 100)
    window.fbAsyncInit = function() {
      window.FB.init({
        xfbml: true,
        version: 'v7.0',
      })
    }
    ;(function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) return
      js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js'
		fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  }

  handleToggleMenu() {
    this.setState({
      isMenuVisible: !this.state.isMenuVisible,
	 })
	 trackCustomEvent({
		category: '漢堡',
		action: 'Click',
		label: 'Gatsby Google Analytics',
	 })
  }

  render() {
    const { children } = this.props
    return (
      <div
        className={`body ${this.state.loading} ${
          this.state.isMenuVisible ? 'is-menu-visible' : ''
        }`}
      >
        <div id="wrapper">
          <Header onToggleMenu={this.handleToggleMenu} />
          {children}
          <Contact />
          <Footer />
          <div id="fb-root" />
          <div
            className="fb-customerchat"
            attribution="setup_tool"
            page_id={107493774239485}
            theme_color="#fcce10"
            logged_in_greeting="嗨！健客你好～有什麼需要幫忙的嗎？"
            logged_out_greeting="嗨！健客你好～有什麼需要幫忙的嗎？"
          />
        </div>
        <Menu onToggleMenu={this.handleToggleMenu} />
      </div>
    )
  }
}

export default Layout
