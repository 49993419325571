import React, { useState } from 'react'

const Contact = (props) => {
	const [status, setStatus] = useState('')

	function submitForm(ev) {
		ev.preventDefault();
		const form = ev.target;
		const data = new FormData(form);
		const xhr = new XMLHttpRequest();
		xhr.open(form.method, form.action);
		xhr.setRequestHeader("Accept", "application/json");
		xhr.onreadystatechange = () => {
		  if (xhr.readyState !== XMLHttpRequest.DONE) return;
		  if (xhr.status === 200) {
			 form.reset();
			 setStatus("SUCCESS");
		  } else {
			 setStatus( "ERROR" );
		  }
		};
		xhr.send(data);
	 }
	return(
    <section id="contact">
        <div className="inner">
            <section>
                <form onSubmit={submitForm}
        action="https://formspree.io/mvowgdby"
        method="POST">
                    <div className="field half first">
                        <label htmlFor="name">Name</label>
                        <input type="text" name="name" id="name" />
                    </div>
                    <div className="field half">
                        <label htmlFor="email">Email</label>
                        <input type="text" name="email" id="email" />
                    </div>
                    <div className="field">
                        <label htmlFor="message">Message</label>
                        <textarea name="message" id="message" rows="6"></textarea>
                    </div>
                    <ul className="actions">
                        <li><input type="submit" value="Send Message" className="special" /></li>
                        <li><input type="reset" value="Clear" /></li>
                    </ul>
						  
                </form>
					 {status === "SUCCESS" ? <p style={{color:'#fcce10'}}>您的信件已成功送出！！</p> :""}
        				{status === "ERROR" ? <p>Oops! There was an error.</p>:''}
            </section>
            <section className="split">
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-envelope"></span>
                        <h3>Email</h3>
                        <span>3musclers@gmail.com</span>
                    </div>
                </section>
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-phone"></span>
                        <h3>Phone</h3>
								<span>內湖店：02-8751-1915</span><br/>
                			<span>板橋店：02-8258-8025</span><br/>
                			<span>永和店：02-8942-2056</span><br/>
                    </div>
                </section>
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-home"></span>
                        <h3>Address</h3>
                        <span>
								內湖店：台北市內湖區港華街18號<br/>
								板橋店：新北市板橋區莒光路211號b1<br/>
								永和店：新北市永和區民權路80號2樓
                        </span>
                    </div>
                </section>
            </section>
        </div>
    </section>)
}

export default Contact
